<template>
    
    <el-row style="display: block;">
      <el-row>  <!--试卷头部分-->
          <div class="other_header" style="border-radius:8%;margin-top:0px;background:rgba(200, 222, 231,1);z-index: 200;position:fixed;height: 50px;line-height: 50px;text-align: center; color:#5D9CEC ;width:100%;">
            <el-row>
            <el-col :span="1">
              <img style="width:40px;height:40px;margin-top:5px;" src="../../assets/cts.png" />
            </el-col>
            <el-col :span="22">
              <span><font color="black">{{paperName}}</font></span>
            </el-col>
            <el-col :span="1">
              <img style="width:40px;height:40px;" src="../../assets/user.png" />
            </el-col>
            </el-row>
          </div>
      </el-row>
      <el-row class="backstyle" >
        <!--题目列表序号区-->
        <el-col :span="5">
          <div style="position:fixed;width:300px;">
          <el-container  :style="height">
            <el-header class="header_style" style="line-height: 40px;height: 40px;"><font size="3">答题卡</font></el-header>
            <el-main class="content_style" style="margin-left: 0px;display: block;overflow-y: scroll;">
              
              <el-row v-for="(chp,c_index) in chapters" :key="c_index" style="margin-top: -20px;display:flex;flex-wrap: wrap;" >
                <div style="font-size:small;width:100%;">{{chp.title}}</div>
                <div v-for="(member,idx) in chp.arrProblems" :key="idx">
                  <el-button v-if="chp.rule_type!=1532" @click="Submit(member.seq_group)" class="button_style" :style="member.arrExams[0].show">{{member.seq_group}}</el-button>
                  <div v-else>
                    <el-button  @click="Submit(cmember.seq_no+member.seq_group-1)" class="button_style" v-for="(cmember,cidx) in member.arrExams" :key="cidx" :style="cmember.show">{{cmember.seq_no+member.seq_group-1}}</el-button>
                  </div>
                </div>
              </el-row>
            </el-main>
          </el-container>
          </div>
        </el-col>

        <el-col :span="16" style="min-height: 1px;">
                <!--中间试题区-->
                <el-container>
                    <el-main class="content_style" style="margin-left: 0px;">
                      <el-container>
                        
                        <el-main>
                          <div v-for="(chp,c_index) in chapters" :key="c_index">
                            <el-row style="margin-bottom:0px;"><font size="3"><b>{{chp.title}}</b></font></el-row>
                            <div v-for="(item,index) in chp.arrProblems" :key="index" >
                              <el-row v-if="chp.rule_type!=1532"  style="margin-bottom: 10px;margin-top:10px;"></el-row>
                              <el-row v-else  style="margin-bottom: 10px;margin-top:10px;">
                                <el-col :span="23" >
                                  <el-row style="rgb(228,228,228);">
                                    <span style="white-space:pre-wrap;line-height:26px;font-family: cursive;"><font size="3"> {{item.exams}}</font></span>
                                  </el-row>
                                </el-col>
                              </el-row>

                            <div  v-if="item.exam_type !='1532'" >
                            <div  v-for="(member,midx) in item.arrExams" :key="midx" style="width:100%;">
                            <el-row :class="`classabc${member.seq_no+item.seq_group-1}`" style="margin-bottom: -8px;margin-top:-10px;">
                              
                              <el-col :span="2" >
                                <span v-if="member.exam_type==1528" class="i_style">{{member.seq_no+item.seq_group-1}}<label style="font-size: xx-small;">&#160;单选</label></span>
                                <span v-else-if="member.exam_type==1530" class="i_style">{{member.seq_no+item.seq_group-1}}<label style="font-size: xx-small;">&#160;判断</label></span>
                                <span v-else-if="member.exam_type==1529" class="i_style">{{member.seq_no+item.seq_group-1}}<label style="font-size: xx-small;">&#160;多选</label></span>
                                <span v-else-if="member.exam_type==1531" class="i_style">{{member.seq_no+item.seq_group-1}}<label style="font-size: xx-small;">&#160;填空</label></span>
                                <span v-else-if="member.exam_type==1652" class="i_style">{{member.seq_no+item.seq_group-1}}<label style="font-size: xx-small;">&#160;问答</label></span>
                              </el-col>

                              <el-col :span="21" style="margin-left:-8px;">
                                
                                <el-row  style="rgb(228,228,228);">
                                 <span style="font-family: sans-serif;"><font size="3"> {{member.exams}}</font></span>
                                </el-row>
                                
                                
                                <el-row style="margin-top: -11px;" v-if="member.exam_type==1528||member.exam_type==1530">
                                  <!--单选题，判断题-->
                                  <el-radio-group v-model="member.arrAnswers"  @change='selchange(c_index,index,midx)'>
                                  <div v-for="(citem,idx) in member.arrResults" :key="idx"> 
                                  
                                    <el-radio style="margin-top: 8px;" :label="citem.id" >
                                      <span style="white-space: pre-wrap;"><font size="3">{{citem.title}}</font></span>
                                     </el-radio>
                                    
                                  </div>
                                  </el-radio-group>
                                </el-row>
                                <el-row style="margin-top: -8px;" v-if="member.exam_type==1529">
                                      <el-checkbox-group v-model="member.arrAnswers" @change='selchange(c_index,index,midx)'>
                                        <div v-for="(citem,idx) in member.arrResults" :key="idx"> 
                                          <el-checkbox style="margin-top: 5px;" :label="citem.id">
                                            <span style="white-space: pre-wrap;"><font size="3"> {{citem.title}}</font></span>
                                          </el-checkbox>
                                       </div> 
                                      </el-checkbox-group>
                                  
                                </el-row>
                                  <!--填空题，添加文本框-->
                                  <!-- <div v-else-if="member.arrExams[0].exam_type==1531"> 
                                    <span style="white-space: pre-wrap;"> <font size="3">{{citem}}&nbsp;&nbsp;&nbsp;&nbsp;</font></span>
                                    <span v-show="!member.edit">({{citem}}!)</span>
                                        <el-input v-show="member.edit" v-model="citem.myAnswer" style="width:200px;"></el-input>
                                        <i
                                          :class="{'el-icon-edit': !member.edit, 'el-icon-check': member.edit}"
                                          @click="Fillchange(member.index,c_index,index,midx)"
                                        ></i>
                                  </div> -->
                                  <!--问答题,添加文本框-->
                                  <!-- <div v-else-if="member.arrExams[0].exam_type==1652"> 
                                      <span v-show="!member.edit">(点击输入答案!)</span>
                                        <el-input v-show="member.edit" v-model="member.myAnswer" type="textarea" rows="3" style="width:500px;"></el-input>
                                        <i
                                          :class="{'el-icon-edit': !member.edit, 'el-icon-check': member.edit}"
                                          @click="Answerchange(member.index,c_index,index,midx)"
                                        ></i>
                                  </div> -->

                                
                                
                                
                              </el-col>
                              
                            </el-row>
                            </div>
                            </div>
                            <div v-else>
                                <div  v-for="(member,midx) in item.arrExams" :key="midx" style="width:100%;">
                                    <el-row :class="`classabc${member.seq_no+item.seq_group-1}`" style="margin-bottom: -8px;margin-top:-10px;">
                                      
                                      <el-col :span="2" >
                                        <span v-if="member.exam_type==1528" class="i_style">{{member.seq_no+item.seq_group-1}}<label style="font-size: xx-small;">&#160;单选</label></span>
                                        <span v-else-if="member.exam_type==1530" class="i_style">{{member.seq_no+item.seq_group-1}}<label style="font-size: xx-small;">&#160;判断</label></span>
                                        <span v-else-if="member.exam_type==1529" class="i_style">{{member.seq_no+item.seq_group-1}}<label style="font-size: xx-small;">&#160;多选</label></span>
                                        <span v-else-if="member.exam_type==1531" class="i_style">{{member.seq_no+item.seq_group-1}}<label style="font-size: xx-small;">&#160;填空</label></span>
                                        <span v-else-if="member.exam_type==1652" class="i_style">{{member.seq_no+item.seq_group-1}}<label style="font-size: xx-small;">&#160;问答</label></span>
                                      </el-col>

                                      <el-col :span="21" style="margin-left:-8px;">
                                        
                                        <el-row  style="rgb(228,228,228);">
                                        <span style="font-family: sans-serif;"><font size="3"> {{member.exams}}</font></span>
                                        </el-row>
                                        
                                        
                                        <el-row style="margin-top: -11px;" v-if="member.exam_type==1528||member.exam_type==1530">
                                          <!--单选题，判断题-->
                                          <el-radio-group v-model="member.arrAnswers"  @change='selchange(c_index,index,midx)'>
                                          <div v-for="(citem,idx) in member.arrResults" :key="idx"> 
                                          
                                            <el-radio style="margin-top: 8px;" :label="citem.id">
                                              <span style="white-space: pre-wrap;"><font size="3">{{citem.title}}</font></span>
                                            </el-radio>
                                            
                                          </div>
                                          </el-radio-group>
                                        </el-row>
                                        <el-row style="margin-top: -8px;" v-if="member.exam_type==1529">
                                              <el-checkbox-group v-model="member.arrAnswers" @change='selchange(c_index,index,midx)'>
                                                <div v-for="(citem,idx) in member.arrResults" :key="idx"> 
                                                  <el-checkbox style="margin-top: 5px;" :label="citem.id">
                                                    <span style="white-space: pre-wrap;"><font size="3"> {{citem.title}}</font></span>
                                                  </el-checkbox>
                                              </div> 
                                              </el-checkbox-group>
                                          
                                        </el-row>
                                          

                                        
                                        
                                        
                                      </el-col>
                                      
                                    </el-row>
                                </div>
                            </div>
                            </div>
                          </div>
                          <el-row style="margin-top: 60px;text-align: center;">
                              <el-col :span="3">
                                <div>
                                  <div class="mask" v-if="showModal" @click="showModal=false"></div>
                                  <div class="pop" v-if="showModal" style="display:inline-block;text-align:center;">
                                    <div><img src="../../assets/question.png" /></div>
                                    <div style="margin-top:10px;"><span><font size="3">确认交卷吗？</font></span></div>
                                    <div style="margin-top:40px;">
                                      <el-button  type="success" style="width:100px;" @click="doHandWork();">确定</el-button>
                                      <el-button  type="info" style="width:100px;margin-left:50px;" @click="showModal=false">取消</el-button>
                                    </div>
                                  </div>
                                  <el-button @click="showModal=true" style="width:100px;" type="primary" >交卷</el-button>
                                </div>
                              </el-col>
                              <el-col :span="3">
                                <el-button type="primary" style="width:100px;">取消</el-button>
                              </el-col>
                            </el-row>
                        </el-main>
                      </el-container>
                    </el-main>
                </el-container>
            
        </el-col>

        <!--定时器-->
        <el-col :span="3">
            <div style="position:fixed;width:180px;">
              <TimeCount ref="TimeCount" :parent_msg="time_length" @HandPaper="doHandIn" @TimeHand="doHandWork"></TimeCount>
            </div>
        </el-col>

      </el-row>
    </el-row>
      
</template>

<script>
import TimeCount from "./TimeCount.vue";
import {queryTestPaperDtl,HandInPaper} from '../../api/data.js';
import global from '@/global';
export default {
   data(){
     return{
       height: {
            height: window.innerHeight-60 + 'px'
        },
        time_length:1,  //考试时长
        TestPaperId:'', //试卷号
       MemberTestId:'', //用户测试号 
       showModal: false,
       grades: 0,
       paperName:'',
       problemNums:0, //题目数量
       totalScore:100, //总分数
       btm:'2021-12-12 23:23:30', //开始考试时间
       etm:'2021-12-12 23:59:30', //结束考试时间
       chapters:[] //等待存放后端数据的接收数组
    }
  },
   components:{
    TimeCount
   },
   mounted(){
      
      //禁用浏览器返回键
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.disableBrowserBack);
      //document.documentElement.webkitRequestFullScreen(); //全屏
   },
   created(){
     this.update();//在html加载完成后进行，相当于在页面上同步显示后端数据
     this.$nextTick(() => {
        // 禁用右键
        document.oncontextmenu = new Function("event.returnValue=false");
        // 禁用选择
        document.onselectstart = new Function("event.returnValue=false");
      });
      
   },
   methods:{
     doHandWork(){
        //交卷
       //var arr = new Array(this.problemNums);
       var arr = new Array();
       var indexSeq = 0;
       var ho;
       for (var i=0;i<this.$data.chapters.length;i++)
       {
         for(var j=0;j<this.$data.chapters[i].arrProblems.length;j++)
         {
            if (this.$data.chapters[i].arrProblems[j].exam_type==1532) //案例题
            {
                for(var m=0;m<this.$data.chapters[i].arrProblems[j].choices;m++)
                {
                    ho = new Object();
                    ho.exam_pkid = this.$data.chapters[i].arrProblems[j].arrExams[m].exam_pkid;
                    ho.group_pkid = this.$data.chapters[i].arrProblems[j].arrExams[m].group_pkid;
                    ho.seq_group = this.$data.chapters[i].arrProblems[j].arrExams[m].seq_group;
                    ho.member_answers = this.$data.chapters[i].arrProblems[j].arrExams[m].arrAnswers.toString().replace(/,/g,'');
                    //arr[indexSeq++]=ho;
                    arr.push(ho);
                }
            }
            else //非案例题
            {
                ho = new Object();
                ho.exam_pkid = this.$data.chapters[i].arrProblems[j].arrExams[0].exam_pkid;
                ho.group_pkid = this.$data.chapters[i].arrProblems[j].arrExams[0].group_pkid;
                ho.seq_group = this.$data.chapters[i].arrProblems[j].arrExams[0].seq_group;
                ho.member_answers = this.$data.chapters[i].arrProblems[j].arrExams[0].arrAnswers.toString().replace(/,/g,'');
                //arr[indexSeq++]=ho;
                arr.push(ho);
            }
         }
         
       }
       let obj = {
        userId:global.machineId,
        testPaperId:this.TestPaperId,
        testPaperMemberId:this.MemberTestId,
        arrProblem:arr
      } 
      let that = this; //很重要
      HandInPaper(obj).then(function(res){
        //alert(res.data);
        that.showModal = false;
        //清除试卷存储
        global.clearMyDoingPaper();
        that.$router.push({
                path:'/TestPaperResult',
                name:'TestPaperResult',
                params:{
                  id:that.TestPaperId,
                  testId:that.MemberTestId
                }
              });
      });
     },
     doHandIn(){
       this.showModal = true;
     },
     update(){
       //调用后台查询试卷信息
       var running = global.getMyDoingPaper();
       this.TestPaperId = running.id;
       var testId = running.testId;
      //  this.TestPaperId = this.$route.params.id;
      //  var testId = this.$route.params.testId;
       //alert(routeParams); //看接收到的参数
      let obj = {
        userId:global.machineId,
        testPaperId:this.TestPaperId,
        TestPaperPkid:testId
      }
      let that = this; //很重要
      //判断是否存在未提交的试卷
      
      queryTestPaperDtl(obj).then(function(res){
        that.chapters = new Array();
        that.paperName=res.data[0].test_name;
        that.totalScore = res.data[0].marks;
        that.MemberTestId = testId;
        that.time_length = res.data[0].time_length;
        
        
        var havaDoProblems = 0;
        for(var i=0;i<res.data[0].arrRules.length;i++)
        {
          if (res.data[0].arrRules[i].parent_pkid==0) //最顶层的标题
          {
              var chapter = new Object();
              chapter.title = res.data[0].arrRules[i].title;
              chapter.rule_type = res.data[0].arrRules[i].rule_type;
              chapter.arrProblems = new Array();
              that.chapters.push(chapter); //将对象添加到数组
              for(var j=0;j<res.data[0].arrProblems.length;j++)
              {
                  if (res.data[0].arrProblems[j].exam_type == chapter.rule_type)
                  {
                      var member = res.data[0].arrProblems[j];
                      
                      var answer;
                      //将答案添加进对象中
                      if (member.exam_type!=1532) //非案例题
                      {
                          member.arrExams[0].arrResults = new Array();
                          member.arrExams[0].arrAnswers = new Array();
                          member.arrExams[0].show="background: #D7D7D7;";
                          member.arrExams[0].LastAnswer="";
                          havaDoProblems++;
                          switch(member.arrExams[0].choices)
                          {
                            case 2:
                              answer = new Object();
                              answer.id = "A";
                              answer.title = member.arrExams[0].cha;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "B";
                              answer.title = member.arrExams[0].chb;
                              member.arrExams[0].arrResults.push(answer);
                              break;
                            case 3:
                              answer = new Object();
                              answer.id = "A";
                              answer.title = member.arrExams[0].cha;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "B";
                              answer.title = member.arrExams[0].chb;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "C";
                              answer.title = member.arrExams[0].chc;
                              member.arrExams[0].arrResults.push(answer);
                              break;
                            case 4:
                              answer = new Object();
                              answer.id = "A";
                              answer.title = member.arrExams[0].cha;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "B";
                              answer.title = member.arrExams[0].chb;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "C";
                              answer.title = member.arrExams[0].chc;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "D";
                              answer.title = member.arrExams[0].chd;
                              member.arrExams[0].arrResults.push(answer);
                              break;
                            case 5:
                              answer = new Object();
                              answer.id = "A";
                              answer.title = member.arrExams[0].cha;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "B";
                              answer.title = member.arrExams[0].chb;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "C";
                              answer.title = member.arrExams[0].chc;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "D";
                              answer.title = member.arrExams[0].chd;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "E";
                              answer.title = member.arrExams[0].che;
                              member.arrExams[0].arrResults.push(answer);
                              break;
                            case 6:
                              answer = new Object();
                              answer.id = "A";
                              answer.title = member.arrExams[0].cha;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "B";
                              answer.title = member.arrExams[0].chb;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "C";
                              answer.title = member.arrExams[0].chc;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "D";
                              answer.title = member.arrExams[0].chd;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "E";
                              answer.title = member.arrExams[0].che;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "F";
                              answer.title = member.arrExams[0].chf;
                              member.arrExams[0].arrResults.push(answer);
                              break;
                            case 7:
                              answer = new Object();
                              answer.id = "A";
                              answer.title = member.arrExams[0].cha;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "B";
                              answer.title = member.arrExams[0].chb;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "C";
                              answer.title = member.arrExams[0].chc;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "D";
                              answer.title = member.arrExams[0].chd;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "E";
                              answer.title = member.arrExams[0].che;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "F";
                              answer.title = member.arrExams[0].chf;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "G";
                              answer.title = member.arrExams[0].chg;
                              member.arrExams[0].arrResults.push(answer);
                              break;
                            case 8:
                              answer = new Object();
                              answer.id = "A";
                              answer.title = member.arrExams[0].cha;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "B";
                              answer.title = member.arrExams[0].chb;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "C";
                              answer.title = member.arrExams[0].chc;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "D";
                              answer.title = member.arrExams[0].chd;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "E";
                              answer.title = member.arrExams[0].che;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "F";
                              answer.title = member.arrExams[0].chf;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "G";
                              answer.title = member.arrExams[0].chg;
                              member.arrExams[0].arrResults.push(answer);
                              answer = new Object();
                              answer.id = "H";
                              answer.title = member.arrExams[0].chh;
                              member.arrExams[0].arrResults.push(answer);
                              break;
                          }
                      }
                      else //案例题
                      {
                          for(var m=0;m<member.choices;m++)
                          {
                              member.arrExams[m].arrResults = new Array();
                              member.arrExams[m].arrAnswers = new Array();
                              member.arrExams[m].show="background: #D7D7D7;";
                              member.arrExams[m].LastAnswer="";
                              havaDoProblems++;
                              switch(member.arrExams[m].choices)
                              {
                                case 2:
                                  answer = new Object();
                                  answer.id = "A";
                                  answer.title = member.arrExams[m].cha;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "B";
                                  answer.title = member.arrExams[m].chb;
                                  member.arrExams[m].arrResults.push(answer);
                                  break;
                                case 3:
                                  answer = new Object();
                                  answer.id = "A";
                                  answer.title = member.arrExams[m].cha;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "B";
                                  answer.title = member.arrExams[m].chb;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "C";
                                  answer.title = member.arrExams[m].chc;
                                  member.arrExams[m].arrResults.push(answer);
                                  break;
                                case 4:
                                  answer = new Object();
                                  answer.id = "A";
                                  answer.title = member.arrExams[m].cha;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "B";
                                  answer.title = member.arrExams[m].chb;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "C";
                                  answer.title = member.arrExams[m].chc;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "D";
                                  answer.title = member.arrExams[m].chd;
                                  member.arrExams[m].arrResults.push(answer);
                                  break;
                                case 5:
                                  answer = new Object();
                                  answer.id = "A";
                                  answer.title = member.arrExams[m].cha;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "B";
                                  answer.title = member.arrExams[m].chb;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "C";
                                  answer.title = member.arrExams[m].chc;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "D";
                                  answer.title = member.arrExams[m].chd;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "E";
                                  answer.title = member.arrExams[m].che;
                                  member.arrExams[m].arrResults.push(answer);
                                  break;
                                case 6:
                                  answer = new Object();
                                  answer.id = "A";
                                  answer.title = member.arrExams[m].cha;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "B";
                                  answer.title = member.arrExams[m].chb;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "C";
                                  answer.title = member.arrExams[m].chc;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "D";
                                  answer.title = member.arrExams[m].chd;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "E";
                                  answer.title = member.arrExams[m].che;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "F";
                                  answer.title = member.arrExams[m].chf;
                                  member.arrExams[m].arrResults.push(answer);
                                  break;
                                case 7:
                                  answer = new Object();
                                  answer.id = "A";
                                  answer.title = member.arrExams[m].cha;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "B";
                                  answer.title = member.arrExams[m].chb;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "C";
                                  answer.title = member.arrExams[m].chc;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "D";
                                  answer.title = member.arrExams[m].chd;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "E";
                                  answer.title = member.arrExams[m].che;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "F";
                                  answer.title = member.arrExams[m].chf;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "G";
                                  answer.title = member.arrExams[m].chg;
                                  member.arrExams[m].arrResults.push(answer);
                                  break;
                                case 8:
                                  answer = new Object();
                                  answer.id = "A";
                                  answer.title = member.arrExams[m].cha;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "B";
                                  answer.title = member.arrExams[m].chb;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "C";
                                  answer.title = member.arrExams[m].chc;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "D";
                                  answer.title = member.arrExams[m].chd;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "E";
                                  answer.title = member.arrExams[m].che;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "F";
                                  answer.title = member.arrExams[m].chf;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "G";
                                  answer.title = member.arrExams[m].chg;
                                  member.arrExams[m].arrResults.push(answer);
                                  answer = new Object();
                                  answer.id = "H";
                                  answer.title = member.arrExams[m].chh;
                                  member.arrExams[m].arrResults.push(answer);
                                  break;
                              }
                          }
                      }
                      chapter.arrProblems.push(member);
                  }
              }
          }  
        }

        //初始化checked数组
        that.problemNums = havaDoProblems;
        
        //将试卷写入本地存储
        //if (running.checked==null||running.checked==undefined)
        //{
        //   running = new Object();
        //   running.id = that.TestPaperId;
        //   running.testId = testId;
           running.chapters = Array(that.chapters.length);
        //   running.showType = 1646; //显示类型
           global.setMyDoingPaper(running);
        //} 
        var t = "已做"+havaDoProblems+"/共"+(that.problemNums)+"题";
        that.$refs.TimeCount.showTitle(t);
        that.$refs.TimeCount.beginTiming(that.time_length);
      });
     },
     Submit(idx){
       var wei = "classabc" + idx;
       var el = document.getElementsByClassName(wei)[0];
       console.log(el.offsetTop);
       this.$nextTick(function(){
         window.scrollTo({
            "behavior": "smooth",
            "top": el.offsetTop-10
         });
       });
     },
     countDoAnswer(obj){
       //统计已经做了多少到题
       var n = 0;
       
       var total = obj.problemNums;
       var running = global.getMyDoingPaper();
       for (var i=0;i<obj.$data.chapters.length;i++)
       {
         for(var j=0;j<obj.$data.chapters[i].arrProblems.length;j++)
         {
            if (obj.$data.chapters[i].arrProblems[j].exam_type==1532) //案例题
            {
                for(var m=0;m<obj.$data.chapters[i].arrProblems[j].choices;m++)
                {
                    if (obj.$data.chapters[i].arrProblems[j].arrExams[m].arrAnswers.length>0)
                     n++;
                }
            }
            else //非案例题
            {
                if (obj.$data.chapters[i].arrProblems[j].arrExams[0].arrAnswers.length>0)
                     n++;
            }
         }
         running.chapters[i] = obj.$data.chapters[i];
       }
      
       global.setMyDoingPaper(running);

       var t = "已做"+n+"/共"+(total)+"题";
       obj.$refs.TimeCount.showTitle(t);
     },
    selchange(c_index,index,dixx){
      
      
      if (this.$data.chapters[c_index].arrProblems[index].arrExams[dixx].arrAnswers.length == 0)
      {
        this.$data.chapters[c_index].arrProblems[index].arrExams[dixx].show = "background: #D7D7D7;";
      }
      else
        this.$data.chapters[c_index].arrProblems[index].arrExams[dixx].show = 'background: #00ABEA;';
      this.$options.methods.countDoAnswer(this); //统计已做题目
    },
    selSinglechange(aid,c_index,index,dixx){
      
      // if (this.$data.chapters[c_index].arrProblems[index].arrExams[dixx].arrAnswers.length == 0)
      // {
      //   this.$data.chapters[c_index].arrProblems[index].arrExams[dixx].show = "background: #D7D7D7;";
      //   this.$data.chapters[c_index].arrProblems[index].arrExams[dixx].LastAnswer="";
      // }
      // else
      // {
      //     this.$data.chapters[c_index].arrProblems[index].arrExams[dixx].show = 'background: #00ABEA;';
      //     this.$data.chapters[c_index].arrProblems[index].arrExams[dixx].LastAnswer=this.$data.chapters[c_index].arrProblems[index].arrExams[dixx].arrAnswers[0];
      // }
        
      this.$data.chapters[c_index].arrProblems[index].arrExams[dixx].LastAnswer=this.$data.chapters[c_index].arrProblems[index].arrExams[dixx].arrAnswers;
       

      this.$options.methods.countDoAnswer(this); //统计已做题目
    },
    click(aid,c_index,index,dixx){
      if (this.$data.chapters[c_index].arrProblems[index].arrExams[dixx].arrAnswers.length == 1)
      {
        if (this.$data.chapters[c_index].arrProblems[index].arrExams[dixx].arrAnswers==this.$data.chapters[c_index].arrProblems[index].arrExams[dixx].LastAnswer)
        {
            this.$data.chapters[c_index].arrProblems[index].arrExams[dixx].arrAnswers="";
            this.$data.chapters[c_index].arrProblems[index].arrExams[dixx].show = "background: #D7D7D7;";
        }
        else
          this.$data.chapters[c_index].arrProblems[index].arrExams[dixx].show = 'background: #00ABEA;';
      }
      else
      {
          if (this.$data.chapters[c_index].arrProblems[index].arrExams[dixx].LastAnswer.length==0)
            this.$data.chapters[c_index].arrProblems[index].arrExams[dixx].show = 'background: #00ABEA;';
          
      }
      
    },
    Fillchange(aid,c_index,index,dixx){
      this.$data.chapters[c_index].datalist[index].members[dixx].edit = !this.$data.chapters[c_index].datalist[index].members[dixx].edit;
      var txt="";
      for (var i=0;i<this.$data.chapters[c_index].datalist[index].members[dixx].result.length;i++)
      {
        txt += this.$data.chapters[c_index].datalist[index].members[dixx].result[i].outcome;
      }
      
      if (txt==null||txt==undefined||txt=='')
      {
        this.$data.chapters[c_index].datalist[index].members[dixx].show = "";
      }
      else
        this.$data.chapters[c_index].datalist[index].members[dixx].show = 'background: #00ABEA;';
    },
    Answerchange(aid,c_index,index,dixx){
      
      this.$data.chapters[c_index].datalist[index].members[dixx].edit = !this.$data.chapters[c_index].datalist[index].members[dixx].edit;
      var txt = this.$data.chapters[c_index].datalist[index].members[dixx].myAnswer;
      if (txt==null||txt==undefined||txt=='')
      {
        this.$data.chapters[c_index].datalist[index].members[dixx].show = "";
      }
      else
        this.$data.chapters[c_index].datalist[index].members[dixx].show = 'background: #00ABEA;';
    },
     PritfGrade(){
       let a;
       for (a in this.$data.chapters.datalist){
         if (this.$data.datalist[a].answer === this.$data.datalist[a].radio){
           this.$data.grades = this.$data.grades + 1;
         }
       }
       alert("提交成功");
     },
     gotobutton(c_index,index,idx){
       this.$data.chapters[c_index].datalist[index].members[idx].show = 'background: #00ABEA;';
     },
     disableBrowserBack(){
       history.pushState(null, null, document.URL);
     }
   },
   unmounted(){
     // 清除popstate事件 否则会影响到其他页面
     window.removeEventListener("popstate", this.disableBrowserBack, false);
   },
   watch:{
     //用来检测路由变化，只要一有变化，则掉用某方法
     //'$route':'update'
   }
}
</script>
<style lang="less" scoped>
  .header_style {
		margin-top: 0px;
		border: 1px solid rgb(228, 228, 228);
		text-align: left;
		padding-left: 10px;
	}

	.other_header {
		background: rgb(255, 255, 255);
		font-size: 20px;
		font-weight: 400px;
		color: rgb(143, 201, 211);

	}

.backstyle{
  width: 100%;
  margin-top:40px;
  z-index: 100;
}
	.content_style {
		border: 1px solid rgb(228, 228, 228);
		text-align: left;
		padding-left: 10px;
	}

	.button_style {
		margin-top: 10px;
		margin-left: 18px;
		margin-left: 10px;
		width: 50px;
		text-align: center;
    color: rgb(10, 10, 10);
    background:rgba(231, 236, 240,1);
	}

.button_style:hover{
  color:pink;
  background: rgb(186, 240, 222);
}

.button_style:active{
  color: rgb(248, 4, 4);
}

	.i_style {
		background: rgb(190, 209, 233);
		font-size: small;
		border-radius: 5%;
	}

// /deep/.el-radio__input.is-checked .el-radio__inner {
//   border-color: #ff9213;
//   background-color: #ff9213;
// }
// /deep/.el-radio__inner:hover {
//   border-color: #ff9213;
// }

// /deep/.el-radio__inner {
//   text-overflow: ellipsis;
//   white-space: normal;
//   line-height: 18px;
//   vertical-align: middle;
//   display: inline-block;
//   }
    .mask {
  background-color: rgb(199, 187, 187);
  opacity: 0.3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1
}
    .pop {
  background-color: #fff;
  position: fixed;
  top: 300px;
  left: 500px;
  width: 400px;
  height:200px;
  z-index: 2
}
    .btn {
  background-color:rgb(93, 156, 236);;
  border-radius: 4px;
  border: 1px solid blue;
  padding: 4px 12px;
}
</style>
